export const apiConfig = {
	// apiUrl: 'http://localhost/sistema-clinicas/api/public',
	// apiUrl: 'http://localhost:8888/sistema-clinicas/api/public/',
	// apiUrl: 'https://clinica-upplay.webupplay.com.br/api/public',  // URL de Apresentação de Produto

	// Sistema da Clinica Novva
	// apiUrl: 'https://clinicanovva.webupplay.com.br/api/public',

	// Sistema da Dhermavie
	// apiUrl: 'https://dhermavie.webupplay.com.br/api/public',

	// Sistema da RZT Dermatologia
	apiUrl: 'https://rztdermatologia.webupplay.com.br/api/public',

	// Sistema Homologação
	// apiUrl: 'https://homologacaoclinica.webupplay.com.br/api/public',
	version: '3.5',
	production: true
}


// Sistema Default
	// apiUrl: 'https://clinicas.webupplay.com.br/api/public',

	// Sistema da Dra. Sheila
	// apiUrl: 'https://drasheilamatielo.webupplay.com.br/api/public',

	// Sistema da Dra. Ana Julia
	// apiUrl: 'https://draanajulia.webupplay.com.br/api/public',
	
	// Sistema da Dra. Telma
	// apiUrl: 'https://dratelma.webupplay.com.br/api/public',

	// Sistema da Dra Ane Canevese
	// apiUrl: 'https://draanecanevese.webupplay.com.br/api/public',

	// Sistema da Dra Gianna
	// apiUrl: 'https://dragianna.webupplay.com.br/api/public',
